<template>
  <section class="rtl">
    <div class="d-flex flex-column white">

        <div class="position-relative mx-auto">
          <croppa 
            class="text-center mt-2" 
            v-model="profilePic" 
            :width="150"
            :height="150"
            :quality="4"
            :prevent-white-space="true" 
            @init="onInit" 
            placeholder="" 
            canvas-color="transparent"
            @image-remove="handleNewImage" 
            @file-choose="handleNewImage" 
            :show-remove-button="true"
            remove-button-color="red"
            :remove-button-size="24"
             >
            
            <img slot="initial" crossorigin="anonymous" v-if="doctor.pic" :src="getProfilePic" />
            <img slot="placeholder" src="@/assets/images/profile-circle.png"/>
            
          </croppa>
          <v-icon small class="avatar-select position-absolute rounded-pill primary white--text text-center p-2" @click="profilePic.chooseFile()">
            mdi-pencil 
          </v-icon>
        </div>

        <v-form ref="form" v-model="valid" lazy-validation class="d-flex flex-column mt-2">
          <div class="d-flex flex-column justify-content-between mt-auto">

            <v-text-field outlined v-model="username" type="text"
                          :label="$t('Enter your username')"
              placeholder="مثال: DrAliAhmadi" class="text-center mt-4"
                          :rules="usernameRules" required />

            <div class="d-flex flex-row justify-content-between mt-1">
              <v-text-field outlined v-model="firstName" type="text" :label="$t('Enter your name')"
                class="text-center ms-0 me-1" :rules="[(v) => !!v]" required />

              <v-text-field outlined v-model="lastName" type="text" :label="$t('Enter your last name')"
                class="text-center me-0 ms-1" :rules="[(v) => !!v]" required />
            </div>

            <v-text-field outlined v-model="mobileNumber" type="text"
                          :label="$t('Mobile Number ( Private )')" readonly class="text-center number-field" />


            <v-textarea outlined v-model="bio" type="text" :label="$t('Enter your bio')" counter="255" :rules="bioRules"
              required />

            <v-autocomplete :label="$t('Choose your gender')" v-model="gender" outlined required small-chips
              :no-data-text="$t('No data')" :items="genderItems" item-text="text" item-value="value"
              :rules="[(v) => !!v]">
            </v-autocomplete>
            <v-autocomplete :label="$t('Choose your city')" v-model="chooseCity" :items="cities" item-text="name"
              item-value="name" outlined required small-chips :no-data-text="$t('No data')" :rules="[(v) => !!v]">
            </v-autocomplete>

            <v-autocomplete :menu-props="{ bottom: true, offsetY: true }" v-model="educations" :items="educationItems" chips
              small-chips item-text="value" item-value="id" :label="$t('Educational qualifications')"
              :loading="isLoadingEducation" return-object outlined multiple>
            </v-autocomplete>

            <v-autocomplete :menu-props="{ bottom: true, offsetY: true }" v-model="specialties" :items="specialtyItems" chips
              small-chips item-text="value" item-value="id" :label="$t('Work specialties')"
              :loading="isLoadingSpecialty" return-object outlined multiple>
            </v-autocomplete>

            <v-autocomplete :menu-props="{ bottom: true, offsetY: true }" v-model="activities" :items="activityItems" chips
              small-chips item-text="value" item-value="id" :label="$t('Field of activity')"
              :loading="isLoadingActivity" return-object outlined multiple>
            </v-autocomplete>

            <v-text-field outlined v-model="clinicName" :label="$t('Clinic name')" disabled class="text-center" hide-details />


            <div class="mt-4 mb-2 text-center">
              <v-btn color="primary" large class="col-12" @click="validate()" :loading="submit_loading" :disabled="submit_loading">
                {{$t('Save changes')}}
              </v-btn>
            </div>
          </div>
      </v-form>
    </div>
  </section>
</template>
<script>
import cityDATA from "@/assets/city.json";
import apiService from "@/http/ApiService.js"

export default {
  data() {
    return {
      submit_loading: false,
      
      valid: false,
      progress: 0,
      
      isLoadingEducation: true,
      isLoadingSpecialty: true,
      isLoadingActivity: true,

      picChanged: false,
      uploadedPicUrl: "",
      uploadedThumbnailPicUrl: "",
      profilePic: {},

      cities: cityDATA,
      firstName: "",
      lastName: "",
      username: "",
      mobileNumber: this.$store.getters.mobileNumber,
      bio: "",
      chooseCity: "",
      gender: "",
      clinicName: "",
      genderItems: [
        { text: this.$t("Man"), value: "MAN" },
        { text: this.$t("Woman"), value: "WOMAN" },
        { text: this.$t("Other"), value: "OTHER" },
      ],

      bioRules: [
        v => !!v,
        v => (v && v.length <= 255) || this.$t("The maximum length is 255 characters")
      ],
      usernameRules() {
        return [
          v => !!v || this.$t("Enter your username"),
          v => (v || '').indexOf(' ') < 0 || this.$t("No spaces are allowed"),
          () => this.isAlphanumericValid() || this.$t('Only English letters and numbers are allowed'),
        ];
      },

      educationItems: [],
      educations: [],
      specialtyItems: [],
      specialties: [],
      activityItems: [],
      activities: [],

      doctor: {
        pic: ''
      },

    };
  },
  computed:{
    getProfilePic() {
      return this.doctor.pic.replace("http://", "https://")
    }
  },
  methods: {
    isAlphanumericValid() {
      return /^[a-zA-Z0-9]+$/.test(this.username);
    },
    rand(min, max) {
      return Math.floor(Math.random() * (max - min) ) + min;
    },
    onInit() {
      this.profilePic.addClipPlugin(function (ctx, x, y, w, h) {
        /*
         * ctx: canvas context
         * x: start point (top-left corner) x coordination
         * y: start point (top-left corner) y coordination
         * w: croppa width
         * h: croppa height
        */
        ctx.beginPath()
        ctx.arc(x + w / 2, y + h / 2, w / 2, 0, 2 * Math.PI, true)
        ctx.closePath()
      })
    },
    handleNewImage() {
      console.log("PIC CHANGED");
      this.picChanged = true;
    },
    validate() {
      if (this.$refs.form.validate()) {
        this.UpdateDoctor();
      }
    },
    getDoctorInfoByMyMobile() {
      apiService.getDoctorInfoByMyMobile()
        .then((response) => {
          this.doctor = response.data;
          // this.profilePic = this.doctor.pic;
          this.firstName = this.doctor.firstname;
          this.lastName = this.doctor.lastname;
          this.username = this.doctor.username;
          this.mobileNumber = this.doctor.mobile;
          this.bio = this.doctor.bio;
          this.chooseCity = this.doctor.city;
          this.gender = this.doctor.gender;
          this.clinicName = this.doctor.clinic.name;
          this.activities = this.doctor.activities;
          this.specialties = this.doctor.specialties;
          this.educations = this.doctor.educations;

          this.profilePic.refresh();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getEducations() {
      apiService.getEducations()
        .then((response) => {
          this.educationItems = response.data;
          this.isLoadingEducation = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getSpeciality() {
      apiService.getSpeciality()
        .then((response) => {
          this.specialtyItems = response.data;
          this.isLoadingSpecialty = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getActivities() {
      apiService.getActivities()
        .then((response) => {
          this.activityItems = response.data;
          this.isLoadingActivity = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    UpdateDoctor() {
      if (this.picChanged) {
        this.uploadPic();
      }
      else {
        this.submitForm();
      }
    },
    submitForm() {
      this.submit_loading = true;

      var DoctorDTO = {
        version: this.doctor.version,
        deleted: this.doctor.deleted,
        id: this.doctor.id,
        answerCount: this.doctor.answerCount,
        followerCount: this.doctor.followerCount,
        postCount: this.doctor.postCount,
        score: this.doctor.score,
        firstname: this.firstName,
        lastname: this.lastName,
        username: this.username,
        bio: this.bio,
        mobile: this.mobileNumber,
        gender: this.gender,
        city: this.chooseCity,
        roleCode: this.doctor.roleCode,

        specialties: this.specialties,
        educations: this.educations,
        activities: this.activities,
        pic: this.picChanged ? this.uploadedPicUrl : this.doctor.pic, //TODO
        thumbnailUrl: this.picChanged ? this.uploadedThumbnailPicUrl : this.doctor.thumbnailUrl, //TODO

        address: this.doctor.address,
        consultantAmount: this.doctor.consultantAmount,
        contactDescription: this.doctor.contactDescription,
        enabled: this.doctor.enabled,
        freeCount: this.doctor.freeCount,
        isClinicNumber: this.doctor.isClinicNumber,
        lat: this.doctor.lat,
        lon: this.doctor.lon,
        notificationToken: this.doctor.notificationToken,
        phone: this.doctor.phone,
        registered: this.doctor.registered,
        certifiedBy: this.doctor.certifiedBy,
        emergencyCallStatus: this.doctor.emergencyCallStatus,
        onlineCallStatus: this.doctor.onlineCallStatus,
        phoneCallStatus: this.doctor.phoneCallStatus,
        consultantPaymentType: this.doctor.consultantPaymentType,
      };

      apiService.UpdateDoctor(DoctorDTO)
        .then((response) => {
          this.submit_loading = false;
          if (response.status == 200) {
            this.$router.push("/s/profile");
          }
        })
        .catch((err) => {
          this.submit_loading = false;
          this.$swal({
            icon: "warning",
            text: err ? err.response.data.detail : this.$t("Error"),
            confirmButtonText: this.$t('OK'),
          });
        });
    },
    uploadPic() {
      this.submit_loading = true;
      if (this.profilePic.hasImage()) {
        this.profilePic.generateBlob((blob) => {
            apiService.upload("PROFILE", blob, Date.now() + ".png", (event) => {
              this.progress = Math.round((100 * event.loaded) / event.total);
            })
              .then((response) => {
                this.submit_loading = false;
                this.uploadedPicUrl = response.data.originalFileDownloadUrl;
                this.uploadedThumbnailPicUrl = response.data.thumbnailPicDownloadUrl;
                this.submitForm();
              })
              .catch(() => {
                this.submit_loading = false;
                this.$swal({
                  icon: "warning",
                  text: this.$t('Could not upload the file!'),
                  confirmButtonText: this.$t("OK"),
                });
              });
          },
          'image/png',
          1
        ); // 85% compressed jpeg file
      }
      else {
        console.log("NO IMAGE SELECTED");
        this.$swal({
          icon: "warning",
          text: this.$t('No image selected. Please select an image'),
          confirmButtonText: this.$t("OK"),
        });
        this.submit_loading = false;
      }
    },
  },
  created() {
    this.getDoctorInfoByMyMobile();
    this.getEducations();
    this.getSpeciality();
    this.getActivities();
  },
}
</script>
<style lang="css">
.croppa-container {
  background-color: transparent !important;
}

</style>
